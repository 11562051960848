<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['firstPatchOccurrences'])">
      <single-answer-question
        title="label.firstPatchFlareUps"
        :value="firstPatchOccurrences"
        :options="firstPatchOccurrencesOptions"
        dysfunction="skin-texture"
        @input="onFieldChange('firstPatchOccurrences', $event)"
      />
    </question-with-error-wrap>

    <question-with-error-wrap class="q-mt24 q-mt32-md" :error="getFieldsError(['patchCauses'])">
      <multi-answer-question
        title="label.patchCauses"
        hint="label.selectUpToThree"
        :max-answers="3"
        :value="patchCauses"
        :options="$options.patchCausesOptions"
        dysfunction="skin-texture"
        @input="onFieldChange('patchCauses', $event)"
      />
    </question-with-error-wrap>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';
import MultiAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/multi-answer-question/MultiAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { PATCH_CAUSE, FIRST_PATCH_OCCURRENCE } from '@/modules/questionnaire/api/constants';

const FIRST_PATCH_OCCURRENCE_OPTIONS = [
  { value: FIRST_PATCH_OCCURRENCE.INFANT, text: 'age.infant' },
  { value: FIRST_PATCH_OCCURRENCE.TEENAGER, text: 'age.teenager' },
  { value: FIRST_PATCH_OCCURRENCE.YOUNG_ADULT, text: 'age.youngAdult' },
  { value: FIRST_PATCH_OCCURRENCE.MATURE, text: 'age.mature' }
];

const PATCH_CAUSE_OPTIONS = [
  { value: PATCH_CAUSE.DETERGENTS, text: 'patchCause.detergents' },
  { value: PATCH_CAUSE.FOODS, text: 'patchCause.foods' },
  { value: PATCH_CAUSE.DUST_OR_PETS, text: 'patchCause.dustOrPets' },
  { value: PATCH_CAUSE.VIRUS, text: 'patchCause.virus' },
  { value: PATCH_CAUSE.ITCHY_MATERIALS, text: 'patchCause.itchyMaterials' },
  { value: PATCH_CAUSE.STRESS, text: 'patchCause.stress' },
  { value: PATCH_CAUSE.DRUGS, text: 'patchCause.drugs' },
  { value: PATCH_CAUSE.INJURY, text: 'patchCause.injury' },
  { value: 'unsure', text: 'patchCause.unsure', overridesAll: true }
];

export default {
  name: 'PatchCausesTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion, MultiAnswerQuestion },
  firstPatchOccurrencesOptions: FIRST_PATCH_OCCURRENCE_OPTIONS,
  patchCausesOptions: PATCH_CAUSE_OPTIONS,
  mixins: [stepTemplateMixin],
  props: {
    age: {
      type: Number,
      default: 0
    },
    firstPatchOccurrences: {
      type: String,
      default: ''
    },
    patchCauses: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    firstPatchOccurrencesOptions() {
      if (this.age < 18) {
        return FIRST_PATCH_OCCURRENCE_OPTIONS.slice(0, 2);
      }

      if (this.age > 18 && this.age < 40) {
        return FIRST_PATCH_OCCURRENCE_OPTIONS.slice(0, 3);
      }

      return FIRST_PATCH_OCCURRENCE_OPTIONS;
    }
  }
};
</script>
